import React from 'react';
import { FaChevronRight } from "react-icons/fa";

const Banner = ({ appView }) => {
  return (
    <div className="bg-gray-100 p-4 max-w-full overflow-x-hidden p-12">
      <div className="flex flex-col lg:flex-row justify-center items-center gap-4 lg:gap-8 ">
        {/* Logo with adjustments for object cover and responsiveness */}
        <img
          src="/Images/help321_horizontal.png"
          alt="Help321 Logo"
          className="w-full lg:w-64 object-cover md:w-96" // Adjust for object cover and responsive widths
        />
        {/* Second image with responsive adjustments */}
        <img
          src="/Images/girl-with-down-syndrome-thinking.png"
          alt="girl-with-down-syndrome-thinking"
          className="w-full lg:w-1/2 lg:h-96 md:h-48 object-contain" // Adjust for responsive widths and maintain aspect ratio
        />
      </div>
      <div className='flex justify-end w-full mt-8'>
        {appView !== 'true' &&
          <a href="https://donation.help3-21.ca/" target="_blank" rel="noopener noreferrer">
            <button className='bg-zinc-800 text-white rounded-full shadow-md py-4 px-8 font-bold flex items-center gap-x-2 text-md'>
              DONATE <FaChevronRight />
            </button>
          </a>
        }
      </div>
    </div>
  );
};

export default Banner;
