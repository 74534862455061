import React, { useState } from 'react';
import { Link } from 'react-router-dom'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone, faEnvelope, faLocationDot, faGlobe } from '@fortawesome/free-solid-svg-icons'

const ResultCard = ({ data }) => {
  const {
    PublicName,
    Description,
    PhysicalAddressStreet1,
    PhysicalAddressStreet2,
    PhysicalAddressCity,
    PhysicalAddressProvince,
    PhysicalAddressPostalCode,
    PhoneNumbers,
    Website,
    Email,
    //Hours,
  } = data;

  const [showMore, setShowMore] = useState(false);
  const descriptionToShow = showMore ? Description : Description.slice(0, 150)+'...';

  const renderDescription = (description) => {
    // Replace <li> with <p class="my-list-item">
    const modifiedDescription = description.replace(
      /<li>(.*?)<\/li>/g,
      (match, p1) => `<li class="my-list-item">${p1}</li>`
    );

    return (
      <div className="text-gray-700 mb-4">
        <p dangerouslySetInnerHTML={{ __html: modifiedDescription }} />
        {Description.length > 150 && (
          <button
            className="text-blue-700 font-bold hover:underline focus:outline-none"
            onClick={() => setShowMore(!showMore)}
          >
            {showMore ? 'Show Less' : 'Show More'}
          </button>
        )}
      </div>
    );
  };

  return (
    <div className="bg-zinc-100 p-6 rounded-xl shadow-md mb-4 lg:flex bg-blue-50">
        <div>
      <h2 className="text-xl font-bold mb-4">{PublicName}</h2>
      {renderDescription(descriptionToShow)}

      {PhoneNumbers && PhoneNumbers.length > 0 && (
        <div className="mb-4">
          <ul>
            {PhoneNumbers.map((phone) => (
              <li key={phone.Phone}>
                <a href={`tel:${phone.Phone}`}>
                  <FontAwesomeIcon icon={faPhone} />
                  <p className='inline pl-2'>
                    {phone.Name && <span>{phone.Name}: </span>}
                    {phone.Phone}
                  </p>
                </a>
              </li>
            ))}
          </ul>
        </div>
      )}

      {Website && (
        <div className="mb-4">
            <FontAwesomeIcon icon={faGlobe}/>
            <p className='inline pl-2'>
            <a href={Website} className="text-blue-700 hover:underline" target="_blank" rel="noopener noreferrer">
              {Website}
            </a>
          </p>
        </div>
      )}
      </div>
      {/* right most div */}
      <div className='lg:ml-auto '>
      <address className="mb-4">
      {(PhysicalAddressStreet1||PhysicalAddressCity)&&<FontAwesomeIcon icon={faLocationDot}/>}
      <p className='inline pl-2'>
        {PhysicalAddressStreet1 && (
          <span>
            {PhysicalAddressStreet1}{` `}
          </span>
        )} 
        {PhysicalAddressStreet2 && <span>{PhysicalAddressStreet2}{` `}</span>} 
        {PhysicalAddressCity && <span>{PhysicalAddressCity}</span>}
        </p>
        <p>{PhysicalAddressProvince && <span>{PhysicalAddressProvince} {PhysicalAddressPostalCode && (
          <span>{PhysicalAddressPostalCode}</span>
        )}</span>}</p>
        
      </address>
      {Email && (
        <div className="mb-4">
            {Email&&<FontAwesomeIcon icon={faEnvelope}/>}

          <a href={`mailto:${Email}`} className="text-blue-700 hover:underline pl-2">
            {Email}
          </a>
        </div>
      )}
      <div className='w-full flex items-end lg:justify-end'> {/* Add justify-end to align items to the end */}
        <Link to={`/resource/${data.id}`}>
          <button className='bg-black text-white px-16 py-2 ring-offset-2 hover:ring-2 rounded-full shadow-md w-64'>
            See Service Page
          </button>
        </Link>
      </div>
      </div>
      
    </div>
  );
};

export default ResultCard;
