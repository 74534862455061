import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone, faEnvelope, faLocationDot, faGlobe, faUser } from '@fortawesome/free-solid-svg-icons'
import { faTwitter, faFacebook, faInstagram, faYoutube, faLinkedin } from '@fortawesome/free-brands-svg-icons';

import { useParams, useNavigate } from 'react-router-dom';

export default function Resource() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
const navigate = useNavigate(); 
  const apiKey = process.env.REACT_APP_API_KEY;
  const apiBase = process.env.REACT_APP_API_BASE;
  const { id } = useParams();

  const apiUrl = `${apiBase}/detail?key=${apiKey}&id=${id}&dataset=ca`;

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const { data: response } = await axios.get(apiUrl);

        // Apply modifications to Description and Eligibility properties
        const modifiedData = {
          ...response,
          Description: response.Description.replace(
            /<li>(.*?)<\/li>/g,
            (match, p1) => `<li class="my-list-item">${p1}</li>`
          ),
          Eligibility: response.Eligibility.replace(
            /<li>(.*?)<\/li>/g,
            (match, p1) => `<li class="my-list-item">${p1}</li>`
          ),
        };

        setData(modifiedData);
      } catch (error) {
        console.error(error.message);
      }
      setLoading(false);
    };

    fetchData();
  }, [apiUrl]);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="spinner"></div>
      </div>
    );
  }
  
  
  const handleGoBack = () => {
    navigate(-1); // Go back in history
  };
  return (
    <div className="container mx-auto my-8 p-8 bg-light-gray shadow-md rounded-md">
        <button onClick={handleGoBack} className="mb-4 border-2 border-zinc-200 px-8 py-2 btn-blue">
        Back
    </button>
    <h1 className="text-3xl font-bold mb-4 text-blue">{data.PublicName}</h1>
  
    <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        <div className="">
          <h2 className="text-xl font-semibold mb-2">Description</h2>
          <span dangerouslySetInnerHTML={{ __html: data.Description }} />
          {data.Eligibility && (
            <p>
              <strong>Eligibility:</strong> <span dangerouslySetInnerHTML={{ __html: data.Eligibility }} />
            </p>
          )}
        </div>
        <div>
            <div className='w-full bg-red-300'>

              
            </div>
            <div className='grid gap-8'>
        {/* Contact Information */}
        <div className="">
          <h2 className="text-xl font-semibold mb-2">Contact Information</h2>
          {data.PhysicalAddressStreet1 && (
            <p className='flex gap-x-2'>
              <FontAwesomeIcon icon={faLocationDot}/>
              {data.PhysicalAddressStreet1}, {data.PhysicalAddressCity}, {data.PhysicalAddressProvince} {data.PhysicalAddressPostalCode}
            </p>
          )}
          {data.PhoneNumbers && data.PhoneNumbers[0] && (
            <p className='flex gap-x-2'>
              <FontAwesomeIcon icon={faPhone}/>
              {data.PhoneNumbers[0].Phone}
            </p>
          )}
          {data.Email && (
            <p className='flex gap-x-2'>
              <FontAwesomeIcon icon={faEnvelope}/>
              {data.Email}
            </p>
          )}
          {data.Website && (
            <p className='flex gap-x-2'>
              <FontAwesomeIcon icon={faGlobe}/>
              <a href={data.Website} target="_blank" rel="noopener noreferrer">
                {data.Website}
              </a>
            </p>
          )}

<hr className="border-t border-gray-300 my-4" />



          {data.Contacts &&
            data.Contacts.map((contact, index) => (
              <div key={index}>
                {contact.Name && (
                  <p className='flex gap-x-2 items-center'>
                    <FontAwesomeIcon icon={faUser}/>
                    {contact.Name}, {contact.Title}
                  </p>
                )}
  
                {contact.Email && (
                  <p className='flex gap-x-2 items-center'>
                    <FontAwesomeIcon icon={faEnvelope}/>
                    {contact.Email}
                  </p>
                )}
              </div>
            ))}

<div className='flex space-x-2 mt-2'>
          {data.Facebook && (

              <Link to={data.Facebook} target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faFacebook} size="2x"/>
              </Link>

   
          )}
          {data.YouTube && (
            <Link to={data.YouTube} target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faYoutube} size="2x"/>
            </Link>
          )}
          {data.Instagram && (
            <Link to={data.Instagram} target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faInstagram} size="2x"/>
            </Link>
          )}
          {data.Twitter && (
            <Link to={data.Twitter} target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faTwitter} size="2x"/>
            </Link>
          )}
          {data.LinkedIn && (
            <Link to={data.LinkedIn} target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faLinkedin} size="2x"/>
              </Link>
          )}
          </div>
          </div>
        
  
        {/* Operating Hours */}
        <div  className="">
          <h2 className="text-xl font-semibold mb-2">Operating Hours</h2>
          {data.Hours && <p dangerouslySetInnerHTML={{ __html: data.Hours }} />}
        </div>

            {/* Additional Information */}
        <div className='bg-yellow-400 p-8 rounded-md'>
          <h2 className="text-xl font-semibold mb-2">Additional Information</h2>
  
          {data.ServiceArea && (
            <p>
              <strong>Service Area:</strong> {data.ServiceArea.join(', ')}
            </p>
          )}
          {data.MailingAddressStreet1 && (
            <p>
              <strong>Mailing Address:</strong> {data.MailingAddressStreet1}, {data.MailingAddressCity}, {data.MailingAddressProvince} {data.MailingAddressPostalCode}, {data.MailingAddressCountry}
            </p>
          )}
          {data.UpdatedOn && (
            <p>
              <strong>Last Updated On:</strong> {data.UpdatedOn}
            </p>
          )}
          {data.LanguagesList && (
            <p>
              <strong>Languages:</strong> {data.LanguagesList}
            </p>
          )}
          {data.DisabilitiesAccess && (
            <p>
              <strong>Disabilities Access:</strong> {data.DisabilitiesAccess}
            </p>
          )}
          {data.DocumentsRequired && (
            <p>
              <strong>Documents Required:</strong> {data.DocumentsRequired}
            </p>
          )}
          

        </div>

        </div>

            

        </div>
        
      </div>
    </div>
  );
  
  
}
