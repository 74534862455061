// ErrorBanner.js

import React from "react";

const ErrorBanner = ({ message, colorClass }) => {
  return (
    <div className={`${colorClass} text-white p-4 mb-4`}>
      {message}
    </div>
  );
};

export default ErrorBanner;
