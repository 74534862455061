import React from 'react'
import ResultCard from './ResultCard'

export default function Results({data}) {
  return (
    <div>
        {(data&&data.RecordCount>0)?<div>
            <p className='font-semibold text-xl'>Showing {data.RecordCount} result{data.RecordCount!==1&&'s'} in your area</p>
            {data.Records.map((item)=>{
                return<ResultCard key={item.id} data={item}/>
            })}
        </div>:<div>
            {data&&<p className='font-semibold text-xl'>No results found.</p>}
            </div>}
        
    </div>
  )
}
