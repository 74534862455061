import axios from "axios";

const handleGeocode = async (location) => {
  try {
    const opencageApiKey = process.env.REACT_APP_OPENCAGE_KEY; // Replace with your OpenCage API key
    const response = await axios.get(
      `https://api.opencagedata.com/geocode/v1/json?q=${encodeURIComponent(
        location
      )}&key=${opencageApiKey}`
    );

    if (!response.data || response.data.results.length === 0) {
      console.error('No results found');
      return null;
    }
    console.log(response.data)
    const firstResult = response.data.results[0];
    const { lat, lng } = firstResult.geometry;

    return { latitude: lat, longitude: lng };
  } catch (error) {
    console.error('Error:', error.message);
    return null;
  }
};

export default handleGeocode;
