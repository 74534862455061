import React, { useState, useEffect, useCallback, useRef } from "react";
import axios from "axios";
import Results from "../Components/Results";
import Banner from "../Components/Banner";
import ErrorBanner from "../Components/ErrorBanner";
import { useNavigate, useLocation } from "react-router-dom";
import handleGeocode from "../Helpers/OpenCage";
import { FaPhone } from "react-icons/fa";

const Help321 = () => {
  const navigate = useNavigate();
  const locationHook = useLocation();

  const queryParams = useCallback(() => {
    return new URLSearchParams(locationHook.search);
  }, [locationHook.search]);

  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [locationType, setLocationType] = useState(queryParams().get("locationType") || "proximity");
  const [location, setLocation] = useState(queryParams().get("location") || "");
  const [category, setCategory] = useState(queryParams().get("category") || "");
  const [language, setlanguage] = useState(queryParams().get("language") || "notSpecified");
  const [distance, setdistance] = useState(queryParams().get("distance") || "25");
  const [sortOption, setSortOption] = useState(queryParams().get("sortOption") || "bestMatch");
  const [searchTerms, setSearchTerms] = useState(queryParams().get("searchTerms") || "");
  const [selectedAge, setSelectedAge] = useState(queryParams().get("selectedAge") || "");
  const [isLoading, setIsLoading] = useState(false);
  const appView = queryParams().get("appView") || "false";

  const resultsRef = useRef(null);

  const handleStateChange = (event, setStateFunction) => {
    const value = event.target.value;
    setStateFunction(value);
  };

  const updatePageParams = useCallback(() => {
    const queryString = new URLSearchParams({
      locationType,
      location,
      category,
      language,
      distance,
      sortOption,
      searchTerms,
      selectedAge,
      appView
    }).toString();

    navigate(`?${queryString}`);
  }, [locationType, location, category, language, distance, sortOption, searchTerms, selectedAge, appView, navigate]);

  const handleSearch = useCallback(async (useInitialParams = false) => {
    setError(null);
    setIsLoading(true);

    let searchLocation = useInitialParams ? queryParams().get("location") : location;
    let searchCategory = useInitialParams ? queryParams().get("category") : category;

    if ((!searchCategory && !searchTerms) && !searchLocation) {
      setError("Please complete the missing fields");
      setIsLoading(false);
      return;
    } else if (!searchLocation) {
      setError("Please enter your location");
      setIsLoading(false);
      return;
    } else if (!searchCategory) {
      setError("Please choose a category");
      setIsLoading(false);
      return;
    }

    try {
      const coordinates = await handleGeocode(searchLocation);

      if (!coordinates) {
        console.error('Coordinates not available');
        setIsLoading(false);
        return;
      }

      const { latitude, longitude } = coordinates;
      const searchData = {
        Dataset: "CA",
        Lang: language,
        Communities: searchLocation,
        SearchType: locationType,
        Latitude: latitude,
        Longitude: longitude,
        Distance: distance,
        Search: "term",
        Term: searchCategory || searchTerms,
        FullRecord: 1,
        PageSize:500,
        pageIndex:0,
        sortOrder:"best"
      };

      const apiBase = process.env.REACT_APP_API_BASE;
      const apiKey = process.env.REACT_APP_API_KEY;
      const response = await axios.post(`${apiBase}/search?key=${apiKey}`, searchData);

      setData(response.data);
    } catch (error) {
      console.error('Error making POST request:', error.message);
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  }, [category, distance, language, location, locationType, searchTerms, queryParams]);

  const clearFilters = () => {
    setLocationType("proximity");
    setLocation("");
    setCategory("");
    setlanguage("notSpecified");
    setdistance("25");
    setSortOption("bestMatch");
    setSearchTerms("");
    setSelectedAge("");
    setData(null);
    setError(null);
    navigate('/');
  };

  useEffect(() => {
    updatePageParams();
    if (queryParams().get("location") && queryParams().get("distance") && queryParams().get("category")) {
      handleSearch(true);
    }
  }, [handleSearch, updatePageParams, queryParams]);

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSearch(); // Call your search function when Enter is pressed
    }
  }

  return (
    <div className="relative min-h-screen max-w-full overflow-x-hidden mb-32">
      
      <Banner appView={appView}/>
      {error && <ErrorBanner message={error} colorClass="bg-red-500" />}

      <div className="lg:px-32 sm:px-8">

    

        <div className="pt-6 space-y-2 border-l-4 border-blue-500 pl-4">
          {/* Large Blue Title */}
          <div className="flex items-center gap-x-4">
          {/* <h1 className="text-4xl font-semibold text-blue-500">
            Search for Services
          </h1> */}
      </div>

      <div className="flex-col space-x-2 space-y-2 ">
                <button
                  className={`bg-white border px-4 py-2 rounded-full
                  ${
                    distance === "500"
                      ? "border-blue-500"
                      : "border-gray-300"
                  }`}
                  onClick={() =>
                    handleStateChange(
                      { target: { value: "500" } },
                      setdistance,
                    )
                  }
                >
                  Any
                </button>
                <button
                  className={`bg-white border px-4 py-2 rounded-full ${
                    distance === "5"
                      ? "border-blue-500"
                      : "border-gray-300"
                  }`}
                  onClick={() =>
                    handleStateChange(
                      { target: { value: "5" } },
                      setdistance,
                    )
                  }
                >
                  5km
                </button>
                <button
                  className={`bg-white border px-4 py-2 rounded-full ${
                    distance === "10"
                      ? "border-blue-500"
                      : "border-gray-300"
                  }`}
                  onClick={() =>
                    handleStateChange(
                      { target: { value: "10" } },
                      setdistance,
                    )
                  }
                >
                  10km
                </button>
                <button
                  className={`bg-white border px-4 py-2 rounded-full ${
                    distance === "25"
                      ? "border-blue-500"
                      : "border-gray-300"
                  }`}
                  onClick={() =>
                    handleStateChange(
                      { target: { value: "25" } },
                      setdistance,
                    )
                  }
                >
                  25km
                </button>
                <button
                  className={`bg-white border px-4 py-2 rounded-full ${
                    distance === "50"
                      ? "border-blue-500"
                      : "border-gray-300"
                  }`}
                  onClick={() =>
                    handleStateChange(
                      { target: { value: "50" } },
                      setdistance,
                    )
                  }
                >
                  50km
                </button>
                <button
                  className={` bg-white border px-4 py-2 rounded-full ${
                    distance === "100"
                      ? "border-blue-500"
                      : "border-gray-300"
                  }`}
                  onClick={() =>
                    handleStateChange(
                      { target: { value: "100" } },
                      setdistance,
                    )
                  }
                >
                  100km
                </button>
                {/* Add other distance buttons as needed */}
              </div>

          
          <div className="xl:flex gap-x-8">
              <div>
          {/* Medium Black Title */}
          <h2 className="text-2xl font-semibold text-black my-2">
            Enter Your Location <span className="text-red-500">*</span>
          </h2>

          {/* Text Paragraph */}
          <p className="text-gray-600 mb-4">
            Select the closest town or city, or enter your postal code.
          </p>

          {/* Input Box */}
          <input
            type="text"
            className="w-full border border-gray-300 p-2 rounded-md mb-4"
            placeholder="Enter your City or Postal Code"
            value={location || ''} // Ensure this line is present
            onChange={(e) => handleStateChange(e, setLocation)}
            onKeyPress={handleKeyPress} 
          />

          </div>
              <div>
          {/* Medium Black Title */}
          <h2 className="text-2xl font-semibold text-black my-2">
            Search by Topic
          </h2>
          
          {/* Text Paragraph */}
          <p className="text-gray-600 mb-4">
            You can search by our topic list.
          </p>
          {/* Dropdown with options */}
          <select
                className={`w-96 border border-gray-300 p-2 rounded-md mb-4`}
                value={category}
                onChange={(e) => handleStateChange(e, setCategory)}
                onKeyPress={handleKeyPress} 
              >
                <option value="">Not Specified</option>
        <option value="development disabilities">Development Disabilities</option>
        <option value="ontario disability support program">Ontario Disability Support Program</option>
        <option value="assistive devices">Assistive Devices</option>
        <option value="learning">Learning</option>
        <option value="sports">Sports</option>
        <option value="transportation">Transportation</option>
        <option value="employment programs">Employment Programs</option>
      
              </select>
              </div>

              <div>

<div >
              <h2 className="text-xl font-semibold text-black my-2">Language</h2>
              <p className="text-gray-600 mb-4">
                Display resources that provide services in this language
              </p>
              {/* Dropdown with options */}
              <select
                className={`w-64 border border-gray-300 p-2 rounded-md mb-4 ${
                  language !== "notSpecified" ? "border-blue-500" : ""
                }`}
                value={language}
                onChange={(e) => handleStateChange(e, setlanguage)}
                onKeyPress={handleKeyPress} 
              >
                <option value="notSpecified">Not Specified</option>
                <option value="english">English</option>
                <option value="french">French</option>
              </select>
            </div>
        </div>


              

        </div>
        </div>

        {/* <div className="xl:w-1/2 mb-4">
              <h2 className="text-xl font-semibold text-black">
                Sort Results By
              </h2>
              <p className="text-gray-600 mb-4">
                Resources will be sorted in this order
              </p>
              <div className="flex space-x-2">
                <button
                  className={`bg-white border px-4 py-2 rounded-full ${
                    sortOption === "bestMatch"
                      ? "border-blue-500"
                      : "border-gray-300"
                  }`}
                  onClick={() =>
                    handleStateChange(
                      { target: { value: "bestMatch" } },
                      setSortOption,
                    )
                  }
                >
                  Best Match
                </button>
                <button
                  className={`bg-white border px-4 py-2 rounded-full ${
                    sortOption === "distance"
                      ? "border-blue-500"
                      : "border-gray-300"
                  }`}
                  onClick={() =>
                    handleStateChange(
                      { target: { value: "distance" } },
                      setSortOption,
                    )
                  }
                >
                  Distance
                </button>
                <button
                  className={`bg-white border px-4 py-2 rounded-full ${
                    sortOption === "alphabetically"
                      ? "border-blue-500"
                      : "border-gray-300"
                  }`}
                  onClick={() =>
                    handleStateChange(
                      { target: { value: "alphabetically" } },
                      setSortOption,
                    )
                  }
                >
                  Aphabetically
                </button>
              </div>
              
            </div>   */}

<div className="space-y-2 border-l-4 border-blue-500 pl-4">
          {/* Large Blue Title */}
          <div className="flex items-center mb-4 gap-x-4">
          {/* <h1 className="text-4xl font-semibold text-blue-500">
            Search for Services
          </h1> */}

          {/* Search Button */}
          <button
                className="bg-black text-white px-16 py-2 rounded-full mt-4"
                onClick={handleSearch}
              >
                Search
              </button>

          {/* Conditionally render the Clear Filters button */}
{ (location || category) && (
  <button
    className="bg-gray-300 text-black px-16 py-2 rounded-full mt-4"
    onClick={clearFilters}
  >
    Clear Filters
  </button>
)}

      </div>
      </div>

        <div ref={resultsRef}>
        {/* Show spinner when loading */}
      {isLoading && (
        <div className="flex justify-center items-center">
          <div className="spinner"></div>
        </div>
      )}
          <Results data={data} />
          
        </div>
      </div>

      {/* Sticky Footer */}
      <div className="fixed bottom-0 w-full p-4 text-white text-center z-10" style={{ backgroundColor: '#1968b3' }}>
        <div className="flex justify-center items-center text-sm">
          <p className="mr-2">If you still have questions please give us a call</p>
          <a href="tel:18774636321" className="bg-white px-4 py-2 rounded-lg font-medium flex items-center gap-x-2" style={{ color: '#1968b3', textDecoration: 'none' }}>
            <FaPhone /> 1 877 INFO 3-21
          </a>
        </div>
      </div>
    </div>
  );
};

export default Help321;
