import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Help321 from './Pages/Help321';
import Resource from './Pages/Resource';
import NotFound from './Pages/NotFound';

function App() {
  return (
    <Router>
      <div className="">
        <Routes>
          <Route path="/" element={<Help321 />} />
          <Route path="/resource/:id" element={<Resource />} /> {/* Include :id parameter in the route */}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
